import { useState } from 'react';
import { ResumeService } from 'src/services/api/ResumeService';

export const useResumeUpload = () => {
  const [isUploadReady, setIsUploadReady] = useState(false);
  const [fileUploadError, setFileUploadError] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const MAX_FILE_SIZE = 5000000;
  const ACCEPTED_FILE_TYPES = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  const validateFile = (fileList) => {
    if (!fileList || fileList.length === 0) return 'Resume is required';
    const file = fileList[0];
    if (file.size > MAX_FILE_SIZE) return 'Max file size is 5MB';
    if (!ACCEPTED_FILE_TYPES.includes(file.type))
      return 'Only .pdf, .doc, and .docx files are accepted';
    return true;
  };

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setFileUploadError('');
      const validationResult = validateFile([file]);
      if (validationResult === true) {
        setSelectedFile(file);
        setIsUploadReady(true);
      } else {
        setFileUploadError(validationResult);
        setIsUploadReady(false);
      }
    } catch (error) {
      setFileUploadError(error.message);
      setIsUploadReady(false);
    }
  };

  const uploadResume = async (contact) => {
    if (!selectedFile) throw new Error('No file selected');

    try {
      // Get signed URL
      const signedUrlResponse = await ResumeService.getSignedUrl({
        phone_number: contact,
        file_name: selectedFile.name,
        content_type: selectedFile.type,
        expiration: 3600,
        file_size: selectedFile.size,
      });

      if (!signedUrlResponse?.url) {
        throw new Error('Failed to get upload URL');
      }

      // Upload to S3
      const formData = new FormData();
      Object.keys(signedUrlResponse.fields).forEach((key) => {
        formData.append(key, signedUrlResponse.fields[key]);
      });
      formData.append('file', selectedFile);

      const uploadResponse = await fetch(signedUrlResponse.url, {
        method: 'POST',
        body: formData,
      });
      switch (uploadResponse.status) {
        case 204:
          return `${signedUrlResponse.s3_key}`;
        case 200:
          return `${signedUrlResponse.s3_key}`;
        default:
          // Handle error cases
          throw new Error('Failed to upload resume');
      }
    } catch (error) {
      setFileUploadError(error.message);
      throw error;
    }
  };

  return {
    selectedFile,
    isUploadReady,
    fileUploadError,
    validateFile,
    handleFileSelection,
    uploadResume,
  };
};
