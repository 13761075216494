import React, { useState } from 'react';
import { PlusIcon } from 'lucide-react';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';

export const FormField = ({
  label,
  children,
  error = null,
  id = undefined,
  required = false,
}) => (
  <div>
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
      {required ? <span className="text-red-500 ml-1">*</span> : null}
    </label>
    {React.cloneElement(children, { id })}
    {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
  </div>
);

export const Section = ({ title, children }) => (
  <div className="mb-8 bg-white p-6 rounded-lg shadow-md">
    <h2 className="text-xl font-semibold mb-4">{title}</h2>
    {children}
  </div>
);

export const FormActions = () => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  return (
    <>
      <Section title="">
        <div className="relative p-6 rounded-lg border border-gray-300 bg-gray-50 transition-all duration-200">
          <div className="space-y-4">
            <div className="flex items-start space-x-2">
              <Checkbox
                id="terms"
                checked={acceptedTerms}
                onCheckedChange={setAcceptedTerms}
                className="mt-1"
              />
              <label htmlFor="terms" className="text-sm text-gray-700">
                I accept the{' '}
                <Dialog>
                  <DialogTrigger className="text-blue-600 hover:underline">
                    terms and conditions
                  </DialogTrigger>
                  <DialogContent className="max-w-2xl">
                    <DialogHeader>
                      <DialogTitle className="text-xl font-semibold">
                        Terms and Conditions
                      </DialogTitle>
                    </DialogHeader>
                    <div className="max-h-[60vh] overflow-y-auto">
                      <div className="space-y-4 text-sm text-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900">
                          1. Information Usage
                        </h3>
                        <p>
                          The information you provide will be used for
                          processing your application and communicating with you
                          about opportunities. We handle all information in
                          accordance with our privacy policy.
                        </p>

                        <h3 className="text-lg font-semibold text-gray-900">
                          2. Your Commitments
                        </h3>
                        <ul className="list-disc pl-6 space-y-2">
                          <li>
                            You confirm all provided information is accurate and
                            complete
                          </li>
                          <li>
                            You agree to update your information if any changes
                            occur
                          </li>
                          <li>
                            You understand that false information may result in
                            application rejection
                          </li>
                        </ul>

                        <h3 className="text-lg font-semibold text-gray-900">
                          3. Data Protection
                        </h3>
                        <p>
                          Your personal data will be processed and stored
                          securely. We may share your information with relevant
                          internal teams for recruitment purposes.
                        </p>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </label>
            </div>
            <p className="text-sm text-gray-600">
              By submitting this form, I confirm that all information provided
              is accurate and complete. I understand that this information will
              be processed in accordance with the privacy policy and used to
              process my application.
            </p>
          </div>
        </div>
      </Section>
      <div className="flex justify-end items-center">
        <Button type="submit" disabled={!acceptedTerms}>
          Update Details
        </Button>
      </div>
    </>
  );
};

export const AddButton = ({
  onClick,
  text,
  variant = 'default',
  size = 'sm',
  className = 'flex items-center gap-2',
}) => (
  <Button
    type="button"
    onClick={onClick}
    variant={variant}
    size={size}
    className={className}
  >
    <PlusIcon className="h-4 w-4" />
    {text}
  </Button>
);
