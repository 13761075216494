import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Alert as ErrorAlert, AlertDescription } from 'src/components/ui/alert';
import { Alert } from 'src/components/common/Alert';
import { Eye, EyeOff } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { useToast } from 'src/hooks/use-toast';
import { UserService } from 'src/services/api/UserService';

const ChangePassword = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validatePassword = (value) => {
    const hasMinLength = value.length >= 8;
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[@$!%*?&]/.test(value);

    return (
      hasMinLength &&
      hasLowerCase &&
      hasUpperCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handleOldPasswordChange = (e) => {
    const { value } = e.target;
    setOldPassword(value);
    setOldPasswordError('');

    if (!value.trim()) {
      setOldPasswordError('Current password is required');
    }
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);

    setPasswordError('');
    setConfirmPasswordError('');

    if (!value.trim()) {
      setPasswordError('Password is required');
      return;
    }

    if (!validatePassword(value)) {
      setPasswordError(
        'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character'
      );
    }

    if (confirmPassword && value !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);

    setConfirmPasswordError('');

    if (!value.trim()) {
      setConfirmPasswordError('Please confirm your password');
      return;
    }

    if (value !== password) {
      setConfirmPasswordError('Passwords do not match');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setOldPasswordError('');
    setPasswordError('');
    setConfirmPasswordError('');
    setError('');

    if (!oldPassword.trim() || !password.trim() || !confirmPassword.trim()) {
      if (!oldPassword.trim())
        setOldPasswordError('Current password is required');
      if (!password.trim()) setPasswordError('New password is required');
      if (!confirmPassword.trim())
        setConfirmPasswordError('Please confirm your new password');
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError(
        'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character'
      );
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match');
      return;
    }

    if (oldPassword === password) {
      setPasswordError('New password must be different from current password');
      return;
    }

    try {
      setIsLoading(true);
      const response = await UserService.changePassword({
        current_password: oldPassword,
        new_password: password,
      });

      if (response.success) {
        setShowSuccessAlert(true);
      } else {
        toast({
          variant: 'destructive',
          title: 'Error',
          description:
            response.message || 'Failed to change password. Please try again.',
        });
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        'Failed to change password. Please try again.';
      setError(errorMessage);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAlertClose = () => {
    setShowSuccessAlert(false);
    navigate('/login');
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold mb-2">Change Password</h2>
          <p className="text-gray-600">Please enter your password details</p>
        </div>

        {error && (
          <ErrorAlert variant="destructive" className="mb-4">
            <AlertDescription>{error}</AlertDescription>
          </ErrorAlert>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <div className="relative">
              <Input
                type={showOldPassword ? 'text' : 'password'}
                placeholder="Current Password"
                value={oldPassword}
                onChange={handleOldPasswordChange}
                className={`h-12 ${oldPasswordError ? 'border-red-500' : ''}`}
              />
              <button
                type="button"
                onClick={() => setShowOldPassword(!showOldPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                {showOldPassword ? (
                  <EyeOff className="h-4 w-4" />
                ) : (
                  <Eye className="h-4 w-4" />
                )}
              </button>
            </div>
            {oldPasswordError && (
              <p className="text-sm text-red-500 mt-1 text-left">
                {oldPasswordError}
              </p>
            )}
          </div>

          <div className="space-y-2">
            <TooltipProvider>
              <Tooltip open={showPasswordTooltip}>
                <TooltipTrigger asChild>
                  <div className="relative">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      placeholder="New Password"
                      value={password}
                      onChange={handlePasswordChange}
                      onFocus={() => setShowPasswordTooltip(true)}
                      onBlur={() => setShowPasswordTooltip(false)}
                      className={`h-12 ${
                        passwordError ? 'border-red-500' : ''
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4" />
                      ) : (
                        <Eye className="h-4 w-4" />
                      )}
                    </button>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="right" className="p-2">
                  <div className="text-sm text-left">
                    Password must contain:
                    <div className="space-y-1 mt-1">
                      <div>• At least 8 characters</div>
                      <div>• One uppercase letter</div>
                      <div>• One lowercase letter</div>
                      <div>• One number</div>
                      <div>• One special character (@$!%*?&)</div>
                    </div>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            {passwordError && (
              <p className="text-sm text-red-500 mt-1 text-left">
                {passwordError}
              </p>
            )}
          </div>

          <div className="space-y-2">
            <div className="relative">
              <Input
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                className={`h-12 ${
                  confirmPasswordError ? 'border-red-500' : ''
                }`}
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
              >
                {showConfirmPassword ? (
                  <EyeOff className="h-4 w-4" />
                ) : (
                  <Eye className="h-4 w-4" />
                )}
              </button>
            </div>
            {confirmPasswordError && (
              <p className="text-sm text-red-500 mt-1 text-left">
                {confirmPasswordError}
              </p>
            )}
          </div>

          <Button type="submit" className="w-full h-12" disabled={isLoading}>
            {isLoading ? (
              <div className="flex items-center justify-center">
                <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                Changing Password...
              </div>
            ) : (
              'Change Password'
            )}
          </Button>
        </form>

        <Alert
          isOpen={showSuccessAlert}
          onClose={handleAlertClose}
          title="Success"
          description="Your password has been changed successfully. Please login with your new password."
          actionText="OK"
          onContinue={handleAlertClose}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
