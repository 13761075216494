import React from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import { CalendarIcon } from 'lucide-react';

const months = [
  { value: '01', label: 'Jan' },
  { value: '02', label: 'Feb' },
  { value: '03', label: 'Mar' },
  { value: '04', label: 'Apr' },
  { value: '05', label: 'May' },
  { value: '06', label: 'Jun' },
  { value: '07', label: 'Jul' },
  { value: '08', label: 'Aug' },
  { value: '09', label: 'Sep' },
  { value: '10', label: 'Oct' },
  { value: '11', label: 'Nov' },
  { value: '12', label: 'Dec' },
];

export const MonthYearRangePicker = ({ onChange, value, className }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 1980 + 1 },
    (_, i) => currentYear - i
  ).reverse();

  // Default to empty object if value is undefined
  const employmentPeriod = value || {};
  const { startMonth, startYear, endMonth, endYear } = employmentPeriod;

  // Get valid months for end date based on start date
  const getValidEndMonths = () => {
    if (!startYear || startYear !== endYear) return months;
    return months.filter(
      (month) => parseInt(month.value, 10) >= parseInt(startMonth || '01', 10)
    );
  };

  // Get valid years for end date based on start date
  const getValidEndYears = () => {
    if (!startYear) return years;
    return years.filter((year) => year >= parseInt(startYear, 10));
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {/* From Date */}
      <div className="flex gap-2">
        <Select
          value={startMonth}
          onValueChange={(newValue) =>
            onChange({
              ...employmentPeriod,
              startMonth: newValue,
              // Clear end date if it becomes invalid
              ...(endYear === startYear &&
              parseInt(endMonth, 10) < parseInt(newValue, 10)
                ? { endMonth: '', endYear: '' }
                : {}),
            })
          }
        >
          <SelectTrigger className={`w-[140px] ${className || ''}`}>
            <div className="flex items-center">
              <CalendarIcon className="h-4 w-4 mr-2 opacity-50" />
              <SelectValue placeholder="From" />
            </div>
          </SelectTrigger>
          <SelectContent>
            {months.map((month) => (
              <SelectItem key={month.value} value={month.value}>
                {month.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select
          value={startYear}
          onValueChange={(newValue) =>
            onChange({
              ...employmentPeriod,
              startYear: newValue,
              // Clear end date if it becomes invalid
              ...(parseInt(endYear, 10) < parseInt(newValue, 10)
                ? { endMonth: '', endYear: '' }
                : {}),
            })
          }
        >
          <SelectTrigger className={`w-[120px] ${className || ''}`}>
            <SelectValue placeholder="Year" />
          </SelectTrigger>
          <SelectContent>
            {years.map((year) => (
              <SelectItem key={year} value={year.toString()}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* To Date */}
      <div className="flex gap-2">
        <Select
          value={endMonth}
          onValueChange={(newValue) =>
            onChange({
              ...employmentPeriod,
              endMonth: newValue,
            })
          }
        >
          <SelectTrigger className={`w-[140px] ${className || ''}`}>
            <div className="flex items-center">
              <CalendarIcon className="h-4 w-4 mr-2 opacity-50" />
              <SelectValue placeholder="To" />
            </div>
          </SelectTrigger>
          <SelectContent>
            {getValidEndMonths().map((month) => (
              <SelectItem key={month.value} value={month.value}>
                {month.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Select
          value={endYear}
          onValueChange={(newValue) =>
            onChange({
              ...employmentPeriod,
              endYear: newValue,
              // Clear end month if year changes to same as start year and month is invalid
              ...(newValue === startYear &&
              parseInt(endMonth, 10) < parseInt(startMonth, 10)
                ? { endMonth: '' }
                : {}),
            })
          }
        >
          <SelectTrigger className={`w-[120px] ${className || ''}`}>
            <SelectValue placeholder="Year" />
          </SelectTrigger>
          <SelectContent>
            {getValidEndYears().map((year) => (
              <SelectItem key={year} value={year.toString()}>
                {year}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
