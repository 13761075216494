import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
  const location = useLocation();
  const userDataString = localStorage.getItem('user');
  let userData;

  try {
    userData = JSON.parse(userDataString);
  } catch (error) {
    console.error('Error parsing user data:', error);
    return <Navigate to="/login" replace />;
  }

  // Check both authentication and role in one pass
  if (!userData || !userData.roles?.includes('candidate')) {
    return <Navigate to="/login" replace />;
  }

  // Only allow direct login users to access change password
  if (
    location.pathname === '/change-password' &&
    userData.identityProvider !== 'direct'
  ) {
    return <Navigate to="/dashboard" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
