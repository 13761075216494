import React, { useState } from 'react';
import { Input } from 'src/components/ui/input';
import { Check } from 'lucide-react';
import { FormField } from 'src/components/common/FormField';

export const ResumeUpload = ({
  register,
  errors,
  contactVerified,
  fileUploadError,
  handleFileSelection,
  validateFile,
  isUploadReady,
}) => {
  const [fileName, setFileName] = useState('');
  const registerProps = register('resume', {
    required: 'Resume is required',
    validate: validateFile,
    onChange: (e) => {
      handleFileSelection(e);
      setFileName(e.target.files[0]?.name || '');
    },
  });

  const getUploadStatusMessage = () => {
    if (!contactVerified) return '';
    if (isUploadReady) return 'Resume ready to upload';
    return 'Upload your resume (PDF, DOC, or DOCX format, max 5MB).';
  };

  return (
    <FormField
      label="Resume"
      error={errors.resume?.message || fileUploadError}
      id="resume"
    >
      <div className={`relative ${!contactVerified ? 'opacity-50' : ''}`}>
        <div className="relative">
          <Input
            type="text"
            readOnly
            value={fileName || 'Choose file to upload resume'}
            onClick={() => document.getElementById('resume-file-input').click()}
            className={`
            ${errors.resume || fileUploadError ? 'border-red-500' : ''} 
            cursor-pointer
            ${!contactVerified ? 'cursor-not-allowed' : ''}
            pr-10
          `}
            placeholder="Choose file to upload resume"
            aria-label="Choose file to upload resume"
          />
          <input
            type="file"
            accept=".pdf,.doc,.docx"
            disabled={!contactVerified}
            id="resume-file-input"
            onChange={registerProps.onChange}
            onBlur={registerProps.onBlur}
            name={registerProps.name}
            ref={registerProps.ref}
            className="hidden"
          />
          {isUploadReady && (
            <div className="absolute right-2 top-1/2 -translate-y-1/2">
              <Check className="h-5 w-5 text-green-500" />
            </div>
          )}
        </div>
      </div>
      <p className="mt-1 text-xs text-gray-500">{getUploadStatusMessage()}</p>
    </FormField>
  );
};
