export const defaultValues = {
  firstname: '',
  middlename: '',
  lastname: '',
  candidateId: '',
  email: '',
  contact: '',
  educations: [
    {
      institution: '',
      qualification: '',
      graduationYear: '',
      fieldsOfStudy: '',
      gradeType: 'MARKS',
      maxMarks: '',
      earnedMarks: '',
      maxGPA: '',
      earnedGPA: '',
      board: '',
      university: '',
    },
  ],
  workExperiences: [
    {
      company: '',
      role: '',
      employment_period: {
        startMonth: '',
        startYear: '',
        endMonth: '',
        endYear: '',
      },
    },
  ],
  skills: [],
  otherSkills: '',
  certifications: [],
  otherCertifications: '',
};

export const educations = [
  { id: 1, value: 'TENTH', label: 'Secondary' },
  { id: 2, value: 'TWELFTH', label: 'Higher Secondary' },
  { id: 3, value: 'DEGREE', label: "Bachelor's Degree" },
  { id: 4, value: 'MASTERS', label: "Master's Degree" },
];

export const fieldStudyOptions = [
  { id: 1, value: 'finance', label: 'Finance' },
  { id: 2, value: 'accounting', label: 'Accounting' },
  { id: 3, value: 'economics', label: 'Economics' },
  { id: 4, value: 'business', label: 'Business Administration' },
  { id: 5, value: 'law', label: 'Law' },
  { id: 6, value: 'riskManagement', label: 'Risk Management' },
  { id: 7, value: 'informationSystems', label: 'Information Systems' },
  { id: 8, value: 'dataScience', label: 'Data Science/Analytics' },
];
export const boardOptions = [
  { id: 1, value: 'STATE', label: 'State' },
  { id: 2, value: 'CBSE', label: 'CBSE' },
  { id: 3, value: 'ICSE', label: 'ICSE' },
  { id: 4, value: 'IB', label: 'IB' },
  { id: 5, value: 'IGCSE', label: 'IGCSE' },
];
