import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'src/components/ui/input';
import { Button } from 'src/components/ui/button';
import { PlusIcon } from 'lucide-react';
import { MonthYearRangePicker } from 'src/components/common/MonthYearRangePicker';
import { FormField } from 'src/components/common/FormField';

const isValidDateRange = (startMonth, startYear, endMonth, endYear) => {
  if (!startMonth || !startYear || !endMonth || !endYear) return true;

  const startDate = new Date(`${startYear}-${startMonth}-01`);
  const endDate = new Date(`${endYear}-${endMonth}-01`);

  return startDate <= endDate;
};

export const WorkExperienceFields = ({ index, control, errors, onRemove }) => {
  const handleEmploymentPeriodChange = (onChange, newValue) => {
    const { startMonth, startYear, endMonth, endYear } = newValue;

    // Only validate if we have both start and end dates
    const hasStartDate = !!(startMonth && startYear);
    const hasEndDate = !!(endMonth && endYear);

    if (hasStartDate && hasEndDate) {
      const isValid = isValidDateRange(
        startMonth,
        startYear,
        endMonth,
        endYear
      );

      if (!isValid) {
        onChange({
          ...newValue,
          endMonth: '',
          endYear: '',
        });
        return;
      }
    }

    onChange(newValue);
  };

  return (
    <div className="relative p-6 rounded-lg border border-gray-300 bg-gray-50">
      <div className="space-y-1">
        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-12">
            <FormField label="Company Name">
              <Controller
                name={`workExperiences.${index}.company`}
                control={control}
                rules={{
                  validate: (value, formValues) => {
                    const experience = formValues.workExperiences[index];
                    const hasAnyValue =
                      experience.company ||
                      experience.role ||
                      experience.employment_period.startMonth ||
                      experience.employment_period.startYear ||
                      experience.employment_period.endMonth ||
                      experience.employment_period.endYear;

                    return hasAnyValue && !value
                      ? 'Company name is required'
                      : true;
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Enter company name"
                    className={`${
                      errors?.workExperiences?.[index]?.company
                        ? 'border-red-500 focus:border-red-500'
                        : ''
                    }`}
                  />
                )}
              />
            </FormField>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-x-4 gap-y-1">
          <div className="col-span-12 md:col-span-6">
            <FormField label="Role">
              <Controller
                name={`workExperiences.${index}.role`}
                control={control}
                rules={{
                  validate: (value, formValues) => {
                    const experience = formValues.workExperiences[index];
                    const hasAnyValue =
                      experience.company ||
                      experience.role ||
                      experience.employment_period.startMonth ||
                      experience.employment_period.startYear ||
                      experience.employment_period.endMonth ||
                      experience.employment_period.endYear;

                    return hasAnyValue && !value ? 'Role is required' : true;
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Enter your role"
                    className={`${
                      errors?.workExperiences?.[index]?.role
                        ? 'border-red-500 focus:border-red-500'
                        : ''
                    }`}
                  />
                )}
              />
            </FormField>
          </div>
          <div className="col-span-12 md:col-span-6">
            <FormField label="Employment Period">
              <Controller
                name={`workExperiences.${index}.employment_period`}
                control={control}
                rules={{
                  validate: (value, formValues) => {
                    const experience = formValues.workExperiences[index];
                    const hasAnyValue =
                      experience.company ||
                      experience.role ||
                      value.startMonth ||
                      value.startYear ||
                      value.endMonth ||
                      value.endYear;

                    if (!hasAnyValue) return true;

                    const hasAllDates =
                      value.startMonth &&
                      value.startYear &&
                      value.endMonth &&
                      value.endYear;

                    if (!hasAllDates) return 'Please complete the date range';

                    return (
                      isValidDateRange(
                        value.startMonth,
                        value.startYear,
                        value.endMonth,
                        value.endYear
                      ) || 'End date must be after start date'
                    );
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <div className="space-y-1">
                    <MonthYearRangePicker
                      onChange={(newValue) =>
                        handleEmploymentPeriodChange(onChange, newValue)
                      }
                      value={value}
                      className={`${
                        errors?.workExperiences?.[index]?.employment_period
                          ? 'border-red-500 focus:border-red-500'
                          : ''
                      }`}
                    />
                  </div>
                )}
              />
            </FormField>
          </div>
        </div>

        {onRemove && (
          <div className="flex justify-end mt-1">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={onRemove}
              className="text-red-500 border-red-500 hover:bg-red-50"
            >
              Remove Experience
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export const AddExperienceButton = ({ onClick }) => (
  <Button
    type="button"
    onClick={onClick}
    variant="default"
    size="sm"
    className="flex items-center gap-2"
  >
    <PlusIcon className="h-4 w-4" />
    Add Work Experience
  </Button>
);
