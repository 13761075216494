import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import {
  Loader2,
  Mail,
  Phone,
  MapPin,
  User,
  Briefcase,
  GraduationCap,
  Target,
  Award,
  Edit,
  Building,
  CalendarDays,
} from 'lucide-react';
import { useToast } from 'src/hooks/use-toast';
import { educations, boardOptions } from 'src/components/resume/constants';
import createResumeApi from '../../components/resume/resumeApi';

export const ResumePreview = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [resumeData, setResumeData] = useState(null);

  const resumeApi = React.useMemo(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    return userData?.cid
      ? createResumeApi({
          isProtected: true,
          candidateId: userData.cid,
        })
      : null;
  }, []);

  const loadFormData = async () => {
    try {
      setIsLoading(true);
      const mappedData = await resumeApi.fetchResumeData();

      const enhancedData = {
        ...mappedData,
        skills: mappedData.skills
          .map(
            (skillId) =>
              mappedData.masterData.skills.find(
                (s) => s.id === parseInt(skillId, 10)
              )?.name
          )
          .filter(Boolean),
        certifications: mappedData.certifications
          .map(
            (certId) =>
              mappedData.masterData.certifications.find(
                (c) => c.id === parseInt(certId, 10)
              )?.name
          )
          .filter(Boolean),
        state: mappedData.masterData.states.find(
          (s) => s.id === mappedData.state
        )?.name,
        city: mappedData.masterData.locations[mappedData.state]?.cities.find(
          (c) => c.id === mappedData.city
        )?.name,
        educations: mappedData.educations.map((edu) => ({
          ...edu,
          qualification:
            educations.find((e) => e.value === edu.qualification)?.label ||
            edu.qualification,
          board:
            boardOptions.find((b) => b.value === edu.board)?.label || edu.board,
        })),
      };

      setResumeData(enhancedData);
    } catch (error) {
      console.error('Error loading data:', error);
      toast({
        title: 'Error loading resume data',
        description: error.message || 'Please try again later',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatMonth = (monthNum) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return months[parseInt(monthNum, 10) - 1];
  };
  useEffect(() => {
    if (!resumeApi) {
      setIsLoading(false);
      toast({
        title: 'Authentication required',
        description: 'Please log in to continue',
        variant: 'destructive',
      });
      navigate('/login');
      return;
    }
    loadFormData();
  }, [resumeApi, toast, navigate]);

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
        <Loader2 className="h-8 w-8 animate-spin ahBlueText" />
        <p className="mt-4 text-sm text-gray-600">Loading resume data...</p>
      </div>
    );
  }

  if (!resumeData) {
    return <div>No resume data found. Please fill the form first.</div>;
  }

  return (
    <div className="flex justify-center items-start bg-[#f8fafc]">
      <div className="container mx-auto px-4 py-8 max-w-5xl">
        <div className="card mb-6 !p-4">
          <div className="flex flex-col md:flex-row gap-8 items-start">
            <div className="w-28 h-28 rounded-full ahBlueBg md:flex hidden items-center justify-center text-white text-3xl font-bold flex-shrink-0">
              {`${resumeData.firstname?.[0]}${resumeData.lastname?.[0]}`}
            </div>
            <div className="flex-grow">
              <h1 className="text-2xl font-bold mb-2 text-gray-900 text-left">
                {`${resumeData.firstname} ${
                  resumeData.middlename ? `${resumeData.middlename} ` : ''
                }${resumeData.lastname}`}
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                <div className="flex items-center gap-2">
                  <User className="h-4 w-4 ahBlueText" />
                  <span>Father&apos;s Name: {resumeData.fathersname}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CalendarDays className="h-4 w-4 ahBlueText" />
                  <span>Born {resumeData.dob}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Mail className="h-4 w-4 ahBlueText" />
                  <span>{resumeData.email}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Phone className="h-4 w-4 ahBlueText" />
                  <span>{resumeData.contact}</span>
                </div>
                <div className="flex items-center gap-2">
                  <MapPin className="h-4 w-4 ahBlueText" />
                  <span>{resumeData.addressLine1}</span>
                </div>
                <div className="flex items-center gap-2">
                  <Building className="h-4 w-4 ahBlueText" />
                  <span>{`${resumeData.city}, ${resumeData.state} ${resumeData.zipCode}`}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card !p-4">
          <h2 className="section-title flex items-center gap-1">
            <Briefcase className="h-5 w-5 ahBlueText" />
            <span>Work Experience</span>
          </h2>
          {resumeData.workExperiences?.map((exp) => (
            <div
              key={`${exp.company}-${exp.role}`}
              className="bgBlueHighlight p-4 rounded-xl mb-4 last:mb-0 text-left"
            >
              <h3 className="text-md font-semibold ahBlueText">{exp.role}</h3>
              <div className="text-gray-600">{exp.company}</div>
              <div className="text-sm text-gray-500 mt-2">
                {`${formatMonth(exp.employment_period.startMonth)} ${
                  exp.employment_period.startYear
                } - ${formatMonth(exp.employment_period.endMonth)} ${
                  exp.employment_period.endYear
                }`}
              </div>
            </div>
          ))}
        </div>

        <div className="card !p-4">
          <h2 className="section-title flex items-center gap-1">
            <GraduationCap className="h-5 w-5 ahBlueText" />
            <span>Education</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-left">
            {resumeData.educations?.map((edu) => (
              <div
                key={`${edu.institution}-${edu.graduationYear}`}
                className="bgBlueHighlight p-4 rounded-xl"
              >
                <div className="flex justify-between items-center mb-1">
                  <h3 className="text-md font-semibold ahBlueText">
                    {edu.qualification}
                  </h3>
                  <p className="text-sm text-gray-600 font-semibold">
                    {edu.graduationYear}
                  </p>
                </div>
                <p>{edu.institution}</p>
                <div className="flex justify-between items-center text-sm text-gray-600 mt-1">
                  <div>
                    {(edu.qualification === 'Secondary' ||
                      edu.qualification === 'Higher Secondary') && (
                      <span>Board: {edu.board}</span>
                    )}
                    {!(
                      edu.qualification === 'Secondary' ||
                      edu.qualification === 'Higher Secondary'
                    ) &&
                      edu.university && <span>{edu.university}</span>}
                  </div>
                  <div className="px-3 py-1 ahBlueBg text-white rounded-full text-sm">
                    {edu.gradeType.toLowerCase() === 'marks' &&
                      `${edu.earnedMarks} / ${edu.maxMarks} Marks`}
                    {edu.gradeType.toLowerCase() === 'gpa' &&
                      `${edu.earnedGPA} / ${edu.maxGPA} GPA`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="card !p-4">
          <h2 className="section-title flex items-center gap-1">
            <Target className="h-5 w-5 ahBlueText" />
            <span>Skills</span>
          </h2>
          <div className="flex flex-wrap gap-2">
            {resumeData.skills?.map((skill) => (
              <span
                key={`skill-${skill}`}
                className="px-4 py-2 bgBlueHighlight text-gray-600 rounded-full hover:ahBlueBg hover:text-white transition-colors"
              >
                {skill}
              </span>
            ))}
            {resumeData.otherSkills &&
              resumeData.otherSkills.split(',').map((skill) => (
                <span
                  key={`other-skill-${skill.trim()}`}
                  className="px-4 py-2 bgBlueHighlight text-gray-600 rounded-full hover:ahBlueBg hover:text-white transition-colors"
                >
                  {skill.trim()}
                </span>
              ))}
          </div>
        </div>

        <div className="card !p-4">
          <h2 className="section-title flex items-center gap-1">
            <Award className="h-5 w-5 ahBlueText" />
            <span>Certifications</span>
          </h2>
          <div className="flex flex-wrap gap-2">
            {resumeData.certifications?.map((cert) => (
              <span
                key={`cert-${cert}`}
                className="px-4 py-2 bgBlueHighlight text-gray-600 rounded-full hover:ahBlueBg hover:text-white transition-colors"
              >
                {cert}
              </span>
            ))}
            {resumeData.otherCertifications &&
              resumeData.otherCertifications.split(',').map((cert) => (
                <span
                  key={`other-cert-${cert.trim()}`}
                  className="px-4 py-2 bgBlueHighlight text-gray-600 rounded-full hover:ahBlueBg hover:text-white transition-colors"
                >
                  {cert.trim()}
                </span>
              ))}
          </div>
        </div>

        <div className="flex justify-end gap-4 mt-6">
          <Button
            variant="outline"
            onClick={() =>
              navigate('/submit-resume', { state: { formData: resumeData } })
            }
          >
            <Edit className="h-4 w-4 mr-2" />
            Edit Resume
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResumePreview;
