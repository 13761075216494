import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

const Confirmation = () => (
  <div className="grid place-items-center h-screen bg-gray-100">
    <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md mx-auto">
      <CheckCircle className="mx-auto h-12 w-12 text-green-500 mb-4" />

      <h2 className="text-2xl font-bold mb-2">Account Created Successfully!</h2>

      <div className="mt-8 pt-6 border-t border-gray-200">
        <Link
          to="/login"
          className="inline-block text-blue-600 hover:text-blue-800 font-medium transition-colors duration-200"
        >
          Login to your account
        </Link>
      </div>
    </div>
  </div>
);

export default Confirmation;
