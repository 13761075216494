// AppRouter.jsx
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'src/components/ui/toaster';

// Import your page components
import GoogleCallback from 'src/components/auth/GoogleCallback';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import Login from '../../pages/Login/Login';
import { UploadResume } from '../../pages/UploadResume/UploadResume';
import { SubmitResume } from '../../pages/SubmitResume/SubmitResume';
import { CreateAccount } from '../../pages/CreateAccount/CreateAccount';
import Confirmation from '../../pages/CreateAccount/Confirmation';
import { Dashboard } from '../../pages/Dashboard/Dashboard';
import ResetPassword from '../../pages/ResetPassword/ResetPassword';
import ForgotPassword from '../../pages/ForgotPassword/ForgotPassword';
import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import AccountActivation from '../../pages/AccountActivation/AccountActivation';
import { ResumeProfile } from '../../pages/ResumeProfile/ResumeProfile';
import { ResumePreview } from '../../pages/ResumePreview/ResumePreview';
import NotFound from '../../pages/NotFound/NotFound';
import PrivateRoute from './PrivateRoute';

const AppRouter = () => (
  <>
    <Toaster />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {/* Public routes */}
          <Route index element={<UploadResume />} />
          <Route path="login" element={<Login />} />
          <Route path="auth/google/callback" element={<GoogleCallback />} />
          <Route path="join-us" element={<UploadResume />} />
          <Route path="create-account" element={<CreateAccount />} />
          <Route path="confirmation" element={<Confirmation />} />
          <Route path="set-password" element={<ResetPassword />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="activate-account" element={<AccountActivation />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />

          {/* Protected routes */}
          <Route element={<PrivateRoute />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="submit-resume" element={<SubmitResume />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="resume-preview" element={<ResumePreview />} />
            <Route path="resume/update" element={<ResumeProfile />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  </>
);

export default AppRouter;
