import React from 'react';
import { Link } from 'react-router-dom';

export function Dashboard() {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto px-4 py-8 pb-24 md:pb-8 max-w-4xl h-screen">
        <h1 className="text-2xl font-bold mb-6 text-left">Welcome!</h1>
        <div className="h-[calc(100%-88px)] bg-white p-6 rounded-lg shadow-md">
          <div className="space-y-6 text-left">
            <div>
              <h2 className="text-xl font-semibold mb-4">
                Resume Submission Process
              </h2>
              <div className="space-y-4 text-gray-600">
                <p>Follow these steps to submit or update your resume:</p>
                <ol className="list-decimal list-inside space-y-2 ml-4">
                  <li>
                    <span className="font-medium">Personal Information:</span>{' '}
                    Fill in your basic details including name, contact
                    information, and address.
                  </li>
                  <li>
                    <span className="font-medium">Educational Background:</span>{' '}
                    Add your academic qualifications from highest to lowest
                    degree.
                  </li>
                  <li>
                    <span className="font-medium">Work Experience:</span> Share
                    your professional journey with company names and roles (if
                    applicable).
                  </li>
                  <li>
                    <span className="font-medium">Skills:</span> List your
                    relevant technical and professional skills.
                  </li>
                  <li>
                    <span className="font-medium">Certifications:</span> Add any
                    professional certifications you&apos;ve earned.
                  </li>
                </ol>
                <div className="mt-6 bg-blue-50 p-4 rounded-md">
                  <p className="text-blue-700 text-sm">
                    <span className="font-medium">Note:</span> Fields marked
                    with an asterisk (*) are mandatory. Make sure to save your
                    changes before leaving the page.
                  </p>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <Link
                to="/submit-resume"
                className="inline-flex items-center justify-center px-6 py-2 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 transition-colors duration-200"
              >
                Update Resume
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
