import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex-1 flex items-center justify-center p-4 bg-gray-100">
      <div className="text-center space-y-6 max-w-lg">
        <h1 className="text-9xl font-bold text-gray-800">404</h1>

        <div className="space-y-2">
          <h2 className="text-3xl font-semibold text-gray-700">
            Page Not Found
          </h2>
          <p className="text-gray-600">
            Oops! The page you&apos;re looking for doesn&apos;t exist or has
            been moved.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
          <Button onClick={() => navigate('/')}>Go Home</Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
