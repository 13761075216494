import React from 'react';
import { Check } from 'lucide-react';
import { OTPInput } from './OTPInput';

const OTPVerificationSection = ({
  isVerifyingOTP,
  handleVerifyOTP,
  otpError,
  canResend,
  resendTimeout,
  handleSendOTP,
  contactVerified,
  isOTPSent,
}) => {
  const formatTime = (totalSeconds) => {
    if (totalSeconds <= 0) return '';

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    if (minutes > 0) {
      return `${minutes} min${minutes > 1 ? 's' : ''} ${seconds} sec`;
    }
    return `${seconds} sec`;
  };

  return (
    <div className="mt-2">
      <div className="flex justify-between items-center mb-1">
        <p className="text-sm text-gray-600">Enter Contact OTP</p>
        {isVerifyingOTP && (
          <span className="text-sm text-blue-600 animate-pulse">
            Verifying...
          </span>
        )}
      </div>

      <OTPInput
        onChange={handleVerifyOTP}
        disabled={isVerifyingOTP || contactVerified}
        length={4}
      />

      {otpError && (
        <p className="mt-1 text-xs text-red-500 font-medium">{otpError}</p>
      )}

      <div className="mt-3 flex flex-col gap-2">
        <div className="flex justify-between items-center">
          {!contactVerified && (
            <div className="text-sm">
              {!canResend ? (
                <span className="text-gray-600">
                  Resend OTP in{' '}
                  <span className="font-medium text-green-500">
                    {formatTime(resendTimeout)}
                  </span>
                </span>
              ) : (
                <button
                  type="button"
                  onClick={() => handleSendOTP('RESEND')}
                  className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
                >
                  Resend OTP
                </button>
              )}
            </div>
          )}

          {contactVerified && (
            <span className="text-sm text-green-600 flex items-center">
              <Check className="w-4 h-4 mr-1" />
              Verified Successfully
            </span>
          )}
        </div>

        {isOTPSent && !contactVerified && (
          <div className="flex flex-col gap-1">
            <p className="text-sm text-gray-600 flex items-center gap-1">
              Didn&apos;t receive the OTP on your phone?
              {canResend && (
                <button
                  type="button"
                  onClick={() => handleSendOTP('RESEND')}
                  className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
                >
                  Request again
                </button>
              )}
            </p>
            {!canResend && (
              <p className="text-xs text-gray-500">
                Please wait for the timer to complete before requesting a new
                OTP
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OTPVerificationSection;
