import React from 'react';
import { Controller } from 'react-hook-form';
import { MultiSelectDropdown } from 'src/components/common/MultiSelectDropdown';
import { Input } from 'src/components/ui/input';

const Section = ({ title, children }) => (
  <div className="mb-8 bg-white p-6 rounded-lg shadow-md">
    <h2 className="text-xl font-semibold mb-4">{title}</h2>
    {children}
  </div>
);

const FormField = ({ label, children, id }) => (
  <div className="mb-4">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-1"
    >
      {label}
    </label>
    {React.cloneElement(children, { id })}
  </div>
);

const transformToOptions = (items) =>
  Array.isArray(items)
    ? items.map((item) => ({
        value: item.id.toString(),
        label: item.name,
      }))
    : [];

export function CertificationsSection({ control, masterData }) {
  const certificationsId = 'certifications-select';
  const otherCertificationsId = 'other-certifications';

  const certificationOptions = React.useMemo(
    () => transformToOptions(masterData?.certifications),
    [masterData?.certifications]
  );

  // Transform API certifications array to match the expected format
  const defaultSelectedCertifications = React.useMemo(() => {
    const certIds =
      masterData?.profile_data?.certifications?.map((cert) =>
        cert.id.toString()
      ) || [];
    return certificationOptions.filter((option) =>
      certIds.includes(option.value)
    );
  }, [masterData?.profile_data?.certifications, certificationOptions]);
  return (
    <Section title="Certifications">
      <div className="relative p-6 rounded-lg border border-gray-300 bg-gray-50 transition-all duration-200">
        <FormField label="Select your certifications" id={certificationsId}>
          <Controller
            name="certifications"
            control={control}
            defaultValue={defaultSelectedCertifications.map(
              (cert) => cert.value
            )}
            render={({ field: { onChange, value = [] } }) => (
              <MultiSelectDropdown
                options={certificationOptions}
                placeholder="Select certifications..."
                onChange={(selected) => {
                  onChange(selected.map((item) => item.value));
                }}
                maxDisplay={3}
                value={certificationOptions.filter((option) =>
                  value?.includes(option.value)
                )}
              />
            )}
          />
        </FormField>

        <FormField
          label="Other certifications (if any)"
          id={otherCertificationsId}
        >
          <Controller
            name="otherCertifications"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Enter other certifications, separated by commas"
              />
            )}
          />
        </FormField>
      </div>
    </Section>
  );
}
