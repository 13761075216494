import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { UserService } from 'src/services/api/UserService';
import { Check } from 'lucide-react';
import loginImg from '../../assets/images/login_img.png';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const validateEmail = (emailId) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(emailId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validate email
    if (!email.trim()) {
      setError('Email is required');
      return;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    try {
      setIsLoading(true);

      // Call forgot password API
      const response = await UserService.forgotPassword({
        email: email.trim(),
      });

      if (response.success) {
        setSuccess(true);
      }
    } catch (err) {
      console.error('Forgot password error:', err);
      setError(err.message || 'Failed to process request. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-screen bg-gray-100 flex overflow-hidden">
      {/* Left Column - Image */}
      <div className="hidden md:block w-[68%] relative">
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${loginImg})`,
            opacity: '0.3',
          }}
        />
        <div className="absolute inset-0 bg-black bg-opacity-30" />
        <div className="relative z-10 h-full flex flex-col justify-center px-16">
          <h1 className="text-4xl font-bold mb-4 ahDarkBlueText">
            Password Reset
          </h1>
          <p className="font-medium text-gray-800 px-8">
            Don&apos;t worry! We&apos;ll help you recover your password. Please
            enter your registered email address, and we&apos;ll send you a
            password reset link.
          </p>
        </div>
      </div>

      {/* Right Column - Form */}
      <div className="w-full md:w-[32%] flex justify-center items-center p-6 overflow-y-auto">
        <div className="w-full max-w-lg">
          {success ? (
            <div className="text-center">
              <div className="mb-4 flex justify-center">
                <Check className="h-12 w-12 text-green-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Email Sent!</h3>
              <p className="text-gray-600 mb-6 text-left">
                Please check your email for password reset instructions.
              </p>
              <p className="text-sm text-gray-500 mb-4 text-left">
                Didn&apos;t receive the email? Check your spam folder or try
                again.
              </p>
              <div className="space-y-4">
                <Button
                  onClick={() => {
                    setSuccess(false);
                    setEmail('');
                  }}
                  variant="outline"
                  className="w-full h-12"
                >
                  Try Again
                </Button>
                <Link to="/login" className="block">
                  <Button className="w-full h-12">Back to Login</Button>
                </Link>
              </div>
            </div>
          ) : (
            <>
              <div className="text-left mb-6">
                <h2 className="text-2xl font-bold mb-2">Forgot Password?</h2>
                <p className="text-gray-600">
                  Enter your email to reset your password
                </p>
              </div>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <Input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => {
                      setError('');
                      setEmail(e.target.value);
                    }}
                    disabled={isLoading}
                    className={`h-12 ${error ? 'border-red-500' : ''}`}
                  />
                  {error && (
                    <p className="mt-1 text-sm text-red-500 text-left">
                      {error}
                    </p>
                  )}
                </div>

                <div className="space-y-4">
                  <Button
                    type="submit"
                    className="w-full h-12"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center">
                        <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full" />
                        Sending...
                      </div>
                    ) : (
                      'Send Reset Link'
                    )}
                  </Button>

                  <Link to="/login" className="block">
                    <Button
                      type="button"
                      variant="outline"
                      className="w-full h-12"
                    >
                      Back to Login
                    </Button>
                  </Link>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
