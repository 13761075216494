import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserService } from 'src/services/api/UserService';
// import { useAuth } from './useAuth';

export const useAuthActions = () => {
  const navigate = useNavigate();
  // const { login: setAuthUser, logout: clearAuthUser } = useAuth();

  const handleAuthError = (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          throw new Error('Invalid credentials');
        case 403:
          throw new Error('Account is locked. Please contact support');
        case 404:
          throw new Error('Account not found');
        case 422:
          throw new Error('Invalid login credentials');
        case 429:
          throw new Error('Too many login attempts. Please try again later');
        default:
          throw new Error(
            error.response.data?.message || 'An error occurred during login'
          );
      }
    } else if (error.request) {
      throw new Error(
        'Unable to connect to the server. Please check your internet connection'
      );
    }
    throw new Error('An unexpected error occurred');
  };

  const logout = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      try {
        const logoutData = {
          refresh_token: refreshToken,
        };
        // Make API call using UserService
        await UserService.logoutUser(logoutData);
      } catch (error) {
        console.error('Login failed:', error);
        throw handleAuthError(error);
      }
    }

    // clearAuthUser();
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');

    navigate('/login');
  };
  const getCidFromStorage = () => {
    try {
      const userData = localStorage.getItem('userData');
      if (!userData) return '0';

      const parsedData = JSON.parse(userData);
      return parsedData?.cid || '0';
    } catch (error) {
      console.error('Error getting CID from storage:', error);
      return '0';
    }
  };

  const processLoginResponse = (data) => {
    const { tokens, user_info: userInfo } = data;

    if (tokens?.access_token) {
      // Store tokens
      localStorage.setItem('token', tokens.access_token);
      if (tokens.refresh_token) {
        localStorage.setItem('refreshToken', tokens.refresh_token);
      }

      // Format user data
      const userData = {
        id: userInfo.sub,
        cid: userInfo.cid,
        email: userInfo.email,
        name: userInfo.name,
        givenName: userInfo.given_name,
        familyName: userInfo.family_name,
        username: userInfo.preferred_username,
        emailVerified: userInfo.email_verified,
        dbSyncStatus: userInfo.dbSyncStatus,
        identityProvider: userInfo.identityProvider,
        roles: userInfo.roles,
      };

      // Set user in auth context
      // setAuthUser(userData);

      // Store user data
      localStorage.setItem('user', JSON.stringify(userData));

      // Log successful login in development
      if (process.env.NODE_ENV === 'development') {
        console.log('User logged in:', userData);
      }

      // Navigate to dashboard
      navigate('/dashboard');

      return data;
    }

    throw new Error('Invalid token received');
  };

  const login = async (credentials) => {
    try {
      // Clear any existing auth state before attempting new login
      logout();

      // Make API call using UserService
      const response = await UserService.loginUser(credentials);

      // Process the login response
      return processLoginResponse(response.data);
    } catch (error) {
      console.error('Login failed:', error);
      throw handleAuthError(error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const response = await UserService.googleAuth();

      if (response.success && response.data.auth_url) {
        // Redirect to Google login
        window.location.href = response.data.auth_url;
        return true;
      }

      throw new Error('Failed to get Google authentication URL');
    } catch (error) {
      console.error('Google login initialization failed:', error);
      throw handleAuthError(error);
    }
  };

  const handleGoogleCallback = async (code) => {
    try {
      // Clear any existing auth state before attempting Google login
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');

      // Call Google callback API
      const cid = getCidFromStorage();
      const response = await UserService.googleAuthCallback({
        code,
        cid,
      });

      if (response.success) {
        // Process the login response
        return processLoginResponse(response.data);
      }

      throw new Error(response.message || 'Google authentication failed');
    } catch (error) {
      console.error('Google callback failed:', error);
      throw handleAuthError(error);
    }
  };

  const refreshAuthToken = async () => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');

      if (!refreshToken) {
        throw new Error('No refresh token available');
      }

      const response = await axios.post('/api/auth/refresh-token', {
        refreshToken,
      });

      const { tokens } = response.data;
      const newToken = tokens.access_token;
      const newRefreshToken = tokens.refresh_token;

      // Update tokens
      localStorage.setItem('token', newToken);
      if (newRefreshToken) {
        localStorage.setItem('refreshToken', newRefreshToken);
      }

      return newToken;
    } catch (error) {
      console.error('Token refresh failed:', error);
      // If refresh fails, log out the user
      logout();
      throw new Error('Session expired. Please login again');
    }
  };

  // Initialize axios interceptor for token refresh
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If error is 401 and we haven't tried refreshing the token yet
      if (error.response?.status === 401 && !originalRequest.isRetry) {
        originalRequest.isRetry = true;

        try {
          // Try to refresh the token
          const newToken = await refreshAuthToken();

          // Update the authorization header
          originalRequest.headers.Authorization = `Bearer ${newToken}`;

          // Retry the original request
          return axios(originalRequest);
        } catch (refreshError) {
          // If refresh fails, throw the error
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  // Set up axios defaults
  axios.defaults.baseURL =
    process.env.REACT_APP_API_URL || 'http://localhost:3000';

  // Create a new interceptor instance to avoid mutating the config parameter
  const requestInterceptor = (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return config;
  };

  // Add the request interceptor
  axios.interceptors.request.use(requestInterceptor);

  return {
    login,
    logout,
    refreshAuthToken,
    handleGoogleLogin,
    handleGoogleCallback,
  };
};
