import React from 'react';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import { Check } from 'lucide-react';
import { FormField } from 'src/components/common/FormField';
import OTPVerificationSection from './OTPVerificationSection';

export const OTPVerification = ({
  type = 'contact', // 'contact' or 'email'
  register,
  errors,
  value,
  verified,
  isOTPSent,
  isOTPLoading,
  showOTPInput,
  isVerifyingOTP,
  otpError,
  canResend,
  resendTimeout,
  handleSendOTP,
  handleVerifyOTP,
  disabled = false,
  readOnly = false,
}) => {
  const config = {
    contact: {
      label: 'Contact',
      placeholder: 'Enter phone number',
      validation: {
        required: 'Contact number is required',
        pattern: {
          value: /^[0-9]{10}$/,
          message: 'Invalid contact number',
        },
      },
    },
    email: {
      label: 'Email',
      placeholder: 'Enter email address',
      validation: {
        required: 'Email is required',
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: 'Invalid email address',
        },
      },
    },
  };

  const registerProps = register(type, config[type].validation);

  return (
    <FormField
      label={config[type].label}
      error={errors[type]?.message}
      id={type}
    >
      <div className="flex gap-2">
        <div className="relative flex-1">
          <Input
            id={type}
            onChange={registerProps.onChange}
            onBlur={registerProps.onBlur}
            name={registerProps.name}
            ref={registerProps.ref}
            className={errors[type] ? 'border-red-500' : ''}
            placeholder={config[type].placeholder}
            disabled={disabled}
            readOnly={readOnly}
            type={type === 'email' ? 'email' : 'text'}
          />
          {verified && (
            <Check className="absolute right-2 top-1/2 -translate-y-1/2 text-green-500 w-5 h-5" />
          )}
        </div>
        <Button
          type="button"
          onClick={() => handleSendOTP('SEND')}
          disabled={
            !value ||
            (type === 'contact' && value.length !== 10) ||
            isOTPSent ||
            isOTPLoading ||
            disabled
          }
          className="whitespace-nowrap"
        >
          {isOTPLoading ? (
            <div className="flex items-center">
              <span className="animate-spin h-4 w-4 mr-2 border-2 border-white border-t-transparent rounded-full" />
              Sending...
            </div>
          ) : (
            'Send OTP'
          )}
        </Button>
      </div>
      {showOTPInput && (
        <OTPVerificationSection
          type={type}
          isVerifyingOTP={isVerifyingOTP}
          handleVerifyOTP={handleVerifyOTP}
          otpError={otpError}
          canResend={canResend}
          resendTimeout={resendTimeout}
          handleSendOTP={handleSendOTP}
          verified={verified}
        />
      )}
    </FormField>
  );
};
