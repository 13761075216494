import api from '../axios.config';
import { API_ENDPOINTS, createEndpoint } from './endpoints';

export const UserService = {
  googleAuth: async () => {
    const response = await api.post(API_ENDPOINTS.USER.GOOGLEAUTH);
    return response;
  },

  googleAuthCallback: async ({ code, cid }) => {
    const endpoint = createEndpoint(API_ENDPOINTS.USER.GOOGLEAUTHCALLBACK, {
      code,
      cid,
    });

    const response = await api.post(endpoint);
    return response;
  },

  verifyUser: async (userData) => {
    const response = await api.post(API_ENDPOINTS.USER.VERIFY, userData);
    return response.data;
  },

  registerUser: async (userData) => {
    const response = await api.post(API_ENDPOINTS.USER.REGISTER, userData);
    return response;
  },

  loginUser: async (userData) => {
    const response = await api.post(API_ENDPOINTS.USER.LOGIN, userData);
    return response;
  },

  logoutUser: async (userData) => {
    const response = await api.post(API_ENDPOINTS.USER.LOGOUT, userData);
    return response;
  },

  activateAccount: async (activateToken) => {
    const endpoint = createEndpoint(API_ENDPOINTS.USER.ACTIVATE, {
      token: activateToken,
    });
    const response = await api.get(endpoint);

    return response;
  },

  sendOtp: async (data) => {
    const response = await api.post(API_ENDPOINTS.USER.SENDOTP, data);
    return response;
  },

  verifyOtp: async (data) => {
    const response = await api.post(API_ENDPOINTS.USER.VERIFYOTP, data);
    return response;
  },

  resendOtp: async (data) => {
    const response = await api.post(API_ENDPOINTS.USER.RESENDOTP, data);
    return response;
  },

  forgotPassword: async (data) => {
    const response = await api.post(API_ENDPOINTS.USER.FORGOTPASSWORD, data);
    return response;
  },

  resetPassword: async (data) => {
    const response = await api.post(API_ENDPOINTS.USER.RESETPASSWORD, data);
    return response;
  },

  changePassword: async (data) => {
    const response = await api.post(API_ENDPOINTS.USER.CHANGEPASSWORD, data);
    return response;
  },
};
