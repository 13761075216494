import React, { useState, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'src/components/ui/input';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'src/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/popover';
import { Button } from 'src/components/ui/button';
import { CheckIcon, ChevronsUpDown } from 'lucide-react';
import { FormField } from './common';

const Combobox = ({
  options,
  value,
  onChange,
  placeholder,
  error,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const selectedOption = options?.find((option) => option?.id === value);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          disabled={disabled}
          className={`w-full justify-between ${
            error ? 'border-red-500 focus:border-red-500' : ''
          } ${!value && 'text-muted-foreground'}`}
        >
          {selectedOption ? selectedOption.name : placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command>
          <CommandInput
            placeholder={`Search ${placeholder.toLowerCase()}...`}
          />
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup className="max-h-[200px] overflow-auto px-5">
            {options.map((option) => (
              <CommandItem
                key={option.id}
                onSelect={() => {
                  onChange(option.id);
                  setOpen(false);
                }}
                className="cursor-pointer"
              >
                <div className="flex items-center justify-between w-full py-1">
                  <span>{option.name}</span>
                  <CheckIcon
                    className={`ml-2 h-4 w-4 ${
                      value === option.id ? 'opacity-100' : 'opacity-0'
                    }`}
                  />
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export const AddressFields = ({ control, errors, masterData }) => {
  const [selectedStateId, setSelectedStateId] = useState(null);

  const citiesByState = useMemo(() => {
    if (!selectedStateId) return [];
    const stateData = masterData.locations[selectedStateId];
    return stateData?.cities || [];
  }, [selectedStateId, masterData.locations]);

  const resetCity = (field) => {
    field.onChange('');
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-x-4 gap-y-6">
        <div className="col-span-12">
          <FormField label="Address Line 1">
            <Controller
              name="addressLine1"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="House/Flat No., Building Name"
                  className={`${
                    errors?.addressLine1
                      ? 'border-red-500 focus:border-red-500'
                      : ''
                  }`}
                />
              )}
            />
          </FormField>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-x-4 gap-y-6">
        <div className="col-span-12">
          <FormField label="Street">
            <Controller
              name="street"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="Street Name"
                  className={`${
                    errors?.street ? 'border-red-500 focus:border-red-500' : ''
                  }`}
                />
              )}
            />
          </FormField>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-x-4 gap-y-6">
        <div className="col-span-12 md:col-span-4">
          <FormField label="State" required>
            <Controller
              name="state"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Combobox
                  options={masterData.states}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    setSelectedStateId(value);
                  }}
                  placeholder="Select state"
                  error={errors?.state}
                />
              )}
            />
          </FormField>
        </div>

        <div className="col-span-12 md:col-span-4">
          <FormField label="City" required>
            <Controller
              name="city"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                // Reset city when state changes
                React.useEffect(() => {
                  if (selectedStateId) {
                    resetCity(field);
                  }
                }, [selectedStateId]);

                return (
                  <Combobox
                    options={citiesByState}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Select city"
                    error={errors?.city}
                    disabled={!selectedStateId}
                  />
                );
              }}
            />
          </FormField>
        </div>

        <div className="col-span-12 md:col-span-4">
          <FormField label="ZIP Code" required>
            <Controller
              name="zipCode"
              control={control}
              rules={{
                required: true,
                pattern: /^[0-9]{6}$/,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  maxLength={6}
                  placeholder="Enter PIN code"
                  className={`${
                    errors?.zipCode ? 'border-red-500 focus:border-red-500' : ''
                  }`}
                />
              )}
            />
          </FormField>
        </div>
      </div>
    </>
  );
};
